.checkout-discount-form {
  display: flex;
  max-width: var(--checkout-forms-max-width);
  min-height: 36px;
}

.checkout-discount-error {
  padding-top: 0.5rem;
  color: #d00;
  font-weight: bold;
}

.checkout-discount-input {
  flex-grow: 1;
  font-family: var(--checkout-font-monospace);
}

.checkout-discount-button {
  flex-shrink: 0;
}

@media screen and (max-width: 629px) {
  .checkout-discount-input {
    flex-grow: 1;
  }

  .checkout-discount-button {
    flex-shrink: 0;
  }
}

@media screen and (min-width: 630px) {
  .checkout-discount-input {
    min-width: 18rem;
  }
}
