.checkout-money-table-discount-code {
  padding: 8px 0 4px 0;
  font-size: 12px;
  opacity: 0.9;
  grid-area: a;
  font-family: var(--checkout-font-monospace);
}

/* Mobile */
@media screen and (max-width: 629px) {
  .checkout-money-table .checkout-amount-value {
    margin-right: 0.5rem;
  }

  .checkout-money-table-percentage {
    padding-top: 0.25rem;
  }
}

/* Desktop */
@media screen and (min-width: 630px) {
  .checkout-money-table {
    min-width: 280px;
    display: grid;
    grid-template-areas:
      "a a b"
      "c c d";
    grid-template-columns: 1fr 1fr 75px;
    grid-gap: 0.5rem;
    text-align: right;
    line-height: 1;
  }

  .checkout-money-table.single-row {
    grid-template-areas: "a a b";
  }

  .checkout-money-table-values {
    grid-area: a;
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    text-align: right;
  }

  .checkout-money-table-type {
    grid-area: b;
    display: flex;
    align-items: flex-end;
  }

  .checkout-money-table-percentage {
    grid-area: c;
  }

  .checkout-money-table-discount-code {
    max-width: 13rem;
    text-align: right;
  }
}
