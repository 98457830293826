.profile-layout {
  display: grid;
  padding-bottom: 3rem;
  grid-template-areas:
    "nav"
    "content"
    "sidebar";
}

.profile-nav {
  grid-area: nav;
  padding-bottom: 3rem;
}

.profile-sidebar {
  grid-area: sidebar;
}

.profile-content {
  grid-area: content;
}

@media (min-width: 768px) {
  .profile-layout {
    grid-template-areas:
      "nav     gutter content"
      "sidebar gutter content";

    grid-template-columns: 300px 8% 1fr;
    grid-template-rows: minmax(100px, auto) auto;
  }
}
