.up-progress-bar {
  --up-progress-bar-accent: rgba(255, 255, 255, 0.3);

  position: relative;
  height: 12px;
  border-radius: 2px;
  border: 1px solid currentColor;
}

.up-progress-bar > div {
  position: absolute;
  overflow: hidden;
  inset: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: currentColor;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.up-progress-bar .animate {
  animation: progressbar 0.5s linear infinite;
  background: linear-gradient(
    -45deg,
    var(--up-progress-bar-accent) 25%,
    transparent 25%,
    transparent 50%,
    var(--up-progress-bar-accent) 50%,
    var(--up-progress-bar-accent) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  background-color: currentColor;
}

@keyframes progressbar {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}
