@keyframes reveal {
  0% {
    top: -80px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes stamp {
  0% {
    transform: scale(1.4) rotate(17deg);
    opacity: 0.4;
  }
  15% {
    transform: scale(0.8) rotate(17deg);
    opacity: 0.5;
  }
  20% {
    transform: scale(1) rotate(17deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) rotate(17deg);
    opacity: 1;
  }
}

.animation-reveal {
  animation: reveal 1250ms cubic-bezier(1, 0.005, 0.02, 1);
  animation-fill-mode: forwards;
}

.animation-stamp {
  animation: stamp 1000ms ease-in-out;
  animation-delay: 1300ms;
  animation-fill-mode: forwards;
}
