/*
  Base styles are set in:
  ./KUP/defaults.css
  ./KUP/variables.css
*/

/*
  VARIABLES
  ------------------------------------------------------------------------------
*/

:root {
  --black: #0c0c0c;
  --teal: #00b5e2;
  --blue-light: #003da5;
  --blue-medium: #012169;
  --blue-dark: #041e42;
  --gray-light: #f0f0f0;
  --gray-medium: #63666a;
  --red: #d50032;
  --font-serif: adobe-caslon-pro, serif;
  --font-sans-serif: futura-pt, sans-serif;

  --accent-primary: var(--blue-dark);

  --body-font-family: var(--font-sans-serif);
  --h1-font-family: var(--font-serif);
  --h2-font-family: var(--font-serif);
  --h3-font-family: var(--font-serif);
  --h4-font-family: var(--font-serif);

  --slice-pt--large: 50px;
  --slice-pb--large: 50px;

  --slice-pt--small: 40px;
  --slice-pb--small: 40px;
}

body {
  color: #0c0c0c;
  font-family: var(--font-sans-serif);
  font-size: 1.1rem;
}

/*
  UTILITY CLASSES
  -----------------------------------------------------------------------------
*/

.font-serif {
  font-family: var(--font-serif);
}
.font-sans {
  font-family: var(--font-sans-serif);
}

.cover-35 {
  position: relative;
}
.cover-35::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(4, 30, 66, 0.5);
  z-index: 1;
}
.cover-35 > * {
  z-index: 2;
}

/*
  THEME BASE
  ------------------------------------------------------------------------------
*/

.theme-white {
  background: #fff;

  h1,
  h2,
  h3 {
    color: var(--blue-dark);
  }
}

.theme-blue-light {
  color: #fff;
  background-color: var(--blue-light);

  a:not(.button) {
    color: #fff;
  }
}

.theme-blue-medium {
  color: #fff;
  background-color: var(--blue-medium);

  a:not(.button) {
    color: #fff;
  }
}

.theme-blue-dark {
  color: #fff;
  background-color: var(--blue-dark);

  a:not(.button) {
    color: #fff;
  }
}

.theme-gray-medium {
  color: #fff;

  a:not(.button) {
    color: #fff;
  }
}

.bg-blue-light {
  background-color: var(--blue-light);
}

.bg-blue-dark {
  background-color: var(--blue-dark);
}

.bg-gray-medium {
  background-color: var(--gray-medium);
}

.bg-campus-gray-light {
  background-image: url(/backgrounds/campus-gray-light.webp);
}
.bg-cherry-blossoms-gray-light {
  background-image: url(/backgrounds/cherry-blossoms-gray-light.webp);
}
.bg-classroom-blue {
  background-image: url(/backgrounds/classroom-blue.webp);
}
.bg-healy-hall-blue {
  background-image: url(/backgrounds/healy-hall-blue.webp);
}
.bg-healy-hall-gray-light {
  background-image: url(/backgrounds/healy-hall-gray-light.webp);
}
.bg-lab-blue {
  background-image: url(/backgrounds/lab-blue.webp);
}
.bg-lab2-gray-light {
  background-image: url(/backgrounds/lab2-gray-light.webp);
}
.bg-statue-gray-light {
  background-image: url(/backgrounds/statue-gray-light.webp);
}
.bg-trees-gray-light {
  background-image: url(/backgrounds/trees-gray-light.webp);
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.theme-gray-light {
  background-color: var(--gray-light);

  h1,
  h2,
  h3 {
    color: var(--blue-dark);
  }
}

.theme-gray-light + .theme-gray-light.slice-padding {
  padding-top: 0;
}

.theme-gray-medium {
  color: #fff;
  background-color: var(--gray-medium);
}

.theme-gray-light .section-instructors h2,
.theme-white h2 {
  font-style: italic;
}

.theme-white .alt-headings h2 {
  margin-bottom: 1.25rem;
  font-size: 24px;
  font-style: normal;
  letter-spacing: 0;
}

.theme-white .alt-headings .grid-cols-2 h2 {
  font-size: 34px;
}

.theme-gray-light h2 {
  font-size: 38px;
}

.theme-white h3,
.theme-gray-light .alt-headings h2 {
  margin-bottom: 1rem;
  font-family: var(--font-sans-serif);
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.1rem;
}

.theme-white h3 {
  margin-bottom: 1.5rem;
}

.theme-blue-medium h3,
.theme-gray-medium h3 {
  color: #fff;
}

.theme-blue-light .text-center h2 {
  --rte-h2-mb: 1rem;
  font-size: 34px;
  letter-spacing: 0;
}

.bg-white {
  background: #fff;
}

.border-white {
  border: 1px solid #fff;
}

a:focus,
button:focus {
  outline: none;
  box-shadow: 0 0 0 4px var(--teal);
}

/*
  HEADINGS
  ------------------------------------------------------------------------------
*/

:root {
  --rte-h1-text-transform: capitalize;
  --rte-h2-text-transform: capitalize;
  --rte-h3-text-transform: capitalize;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  padding-bottom: 0;
  font-family: var(--font-serif);
  font-weight: normal;
  letter-spacing: -0.05rem;
}

h1,
.h1 {
  font-size: 3rem;
  font-style: italic;
}
.theme-gray-light .section-instructors h2,
h2,
.h2 {
  font-size: 34px;
}
h3,
.h3 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
}

@media only screen and (min-width: 650px) {
  h1,
  .h1 {
    font-size: 3.25rem;
  }
  .theme-gray-light .section-instructors h2,
  h2,
  .h2 {
    font-size: 48px;
  }
}

/*
  BUTTON
  ------------------------------------------------------------------------------
*/

:root {
  --button-font-size: 18px;
  --button-border-radius: 0;
  --button-font-weight: 500;
  --button-bg: transparent;
  --button-border-w: 0;
  --button-pl: 0;
  --button-pr: 1.5rem;
  --button-pt: 0.5rem;
  --button-pb: 0.5rem;
}

.button {
  text-align: left;
}

.app-button-primary {
  display: inline-block;
  border: 1px solid var(--blue-dark);
  padding: 0.5rem 1.75rem;
  font-family: var(--font-sans-serif);
  text-align: center;
  background: var(--blue-dark);
  color: #fff;
}

.app-button-secondary {
  display: inline-block;
  border: 1px solid var(--blue-dark);
  padding: 0.5rem 1.75rem;
  color: var(--blue-dark);
  font-family: var(--font-sans-serif);
  background: #fff;
}

// yes, Webkit browsers render one way, Mozilla another
// probably a bug with the Prismic library - 25 Feb, 2021
a .button-block,
.button-block a {
  font-size: 16px;
  text-decoration: none !important;
}

.theme-blue-dark,
.theme-blue-medium,
.theme-blue-light,
.theme-gray-light,
.theme-gray-medium {
  a .button-block,
  .button-block a {
    display: inline-block;
    padding: 0.5rem 1.75rem;
    color: var(--blue-dark);
    font-family: var(--font-sans-serif);
    background: #fff;
  }
}

.theme-white {
  a .button-block,
  .button-block a {
    display: inline-block;
    padding: 0.5rem 1.75rem;
    font-family: var(--font-sans-serif);
    background: var(--blue-dark);
    color: #fff;
  }
}

.theme-blue-light,
.theme-blue-medium,
.theme-blue-dark,
.theme-gray-medium {
  .button {
    color: #fff !important;
    background-image: url(/chevron-white.svg) !important;
    background-position: right;
    background-repeat: no-repeat;
  }
}

.theme-blue-light .button-block .button {
  color: var(--blue-dark) !important;
}

.theme-white,
.theme-gray-light {
  .button {
    color: var(--blue-dark);
    background-image: url(/chevron-blue.svg);
    background-position: right;
    background-repeat: no-repeat;
  }
}

/*
  RICH TEXT
  ------------------------------------------------------------------------------
*/

:root {
  --rte-h3-mb: 1.25rem;
  --rte-h2-mb: 2.5rem;
  --rte-h3-font-family: var(--font-serif);
}

.rte h2 {
  text-transform: none;
}

.rte ul li {
  position: relative;
  padding-left: 0.75rem;
}

.rte ul li::before {
  position: absolute;
  top: -0.35rem;
  left: -1rem;
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 150%;
}

.theme-white,
.theme-gray-light {
  .rte ul li::before {
    color: var(--teal);
  }
}

/*
  EXPANDER
  ------------------------------------------------------------------------------
*/

:root {
  .theme-white {
    .expander {
      color: var(--blue-dark);
      font-weight: 500;
    }
    --expander-icon-bg: var(--blue-dark);
    --accordion-title-bg: var(--gray-light);
  }

  .expander:focus-within {
    outline: none;
    box-shadow: 0 0 0 4px var(--teal);
  }
}

/*
  NAVIGATION
  ------------------------------------------------------------------------------
*/
.custom .navigation {
  --navigation-bg-color: var(--blue-dark);
  --navigation-logo-width: 238px;
  --navigation-logo-height: 40px;
  --navigation-text-color: #fff;
}
.custom .navigation .navigation-logo:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px var(--teal);
}

/* Mobile */
@media only screen and (max-width: 1023px) {
  .custom .navigation {
    --navigation-btn-bg: var(--blue-light);
    --navigation-menu-text-color: var(--blue-dark);

    .navigation-right {
      a,
      button {
        font-size: 15px;
      }
    }

    [data-variant="button-link"],
    .up-nav-signin {
      --navigation-btn-bg: var(--blue-dark);
    }

    [data-variant="button-red"],
    .navigation-desktop-cart {
      --navigation-btn-bg: var(--red);
    }

    button,
    .navigation-menu a {
      &:focus {
        outline: none;
        box-shadow: inset 0 0 0 3px var(--teal);
      }
    }
  }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .custom .navigation {
    --navigation-item-color-focus: #fff;
    --navigation-item-menu-item-color: var(--blue-dark);
    --navigation-item-menu-item-color-hover: var(--blue-dark);
    --navigation-item-text-decoration-focus: underline;
    --navigation-font-size: 17px;
    --navigation-child-item-color: var(--blue-medium);
    --navigation-child-item-color-hover: var(--blue-light);

    --navigation-button-spacing-y: 11px;
    --navigation-text-color: #fff;

    .navigation-children ul li:last-child {
      margin-bottom: 26px;
    }
    .navigation-right {
      align-self: stretch;

      [data-variant="button-white"],
      [data-variant="button-red"],
      .navigation-desktop-cart,
      .up-nav-profile {
        justify-content: center;
        margin-right: 4px;
        border-width: 1px;
        border-style: solid;
        padding: 0 1rem;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
      }

      .up-nav-signin {
        margin-right: 12px;
        border-color: transparent;
        background-color: transparent;
      }

      [data-variant="button-white"],
      .up-nav-profile {
        border-color: #fff;
        background-color: transparent;
      }

      [data-variant="button-red"],
      .navigation-desktop-cart {
        margin-right: 0;
        border-color: var(--red);
        background-color: var(--red);
      }
    }
  }
}

/*
  GRID TYPE: RIBBON
  ------------------------------------------------------------------------------
*/

.type-ribbon {
  & > * {
    text-align: center;
  }

  h3 {
    margin-bottom: 1rem;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.type-ribbon .rte h2 a {
  color: inherit;
}

/*
  HERO
  ------------------------------------------------------------------------------
*/

.georgetown-hero {
  height: 330px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;

  h1 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

@media only screen and (min-width: 700px) {
  .georgetown-hero {
    height: 280px;
  }
}

.georgetown-hero h1 {
  color: #fff;
  text-align: center;
}

.max-875 {
  max-width: 875px;
}

.theme-gray-light .max-875 {
  --rte-body-mb: 2rem;

  color: var(--blue-dark);
  font-family: var(--font-serif);
  font-size: 1.25rem;
  line-height: 1.8;
}

.seal {
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;
  height: 400px;
  opacity: 0.1;
  pointer-events: none;
}

@media only screen and (min-width: 1100px) {
  .flush-images.grid.grid-cols-2 {
    .block-img {
      width: calc(100% + var(--wrapper-px)) !important;
      margin: 0 !important;
    }

    & > :nth-child(odd) > * {
      margin-right: 8%;
    }
    & > :nth-child(even) > * {
      margin-left: 8%;
    }

    & > :nth-child(odd) > .block-img {
      margin-left: calc(-1 * var(--wrapper-px)) !important;
    }
    & > :nth-child(even) > .block-img {
      margin-right: calc(-1 * var(--wrapper-px)) !important;
    }
  }
}

/*
  COURSE LISTING
  ------------------------------------------------------------------------------
*/

:root {
  --course-listing-padding: 3rem;
}

@media screen and (max-width: 550px) {
  :root {
    --course-listing-padding: 1.5rem;
  }
}

.course-listing-image {
  margin-bottom: var(--course-listing-padding);
}

.course-listing-tagline,
.course-listing-category,
.course-listing-title {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.course-listing-category {
  font-family: var(--font-serif);
  font-size: 16px;
  text-transform: uppercase;
}

.course-listing-tagline {
  font-family: var(--font-serif);
  font-size: 32px;
}

.course-listing-title {
  font-weight: bold;
}

.course-listing-body {
  margin-bottom: 2rem;
}

.course-listing-item,
.course-listing {
  --rte-link-text-decoration: none;

  font-family: var(--font-sans-serif);
  padding: 0 var(--course-listing-padding)
    calc(var(--course-listing-padding) - 1rem) var(--course-listing-padding);

  h2,
  .h2,
  h3 {
    font-family: var(--course-listing-padding);
  }

  h2 {
    margin-bottom: 1rem;
    padding-top: 0;
    font-size: 16px;
    text-transform: uppercase;
  }

  h3 {
    font-weight: 900;
    font-size: 32px;
  }

  h2::after {
    display: none;
  }

  img {
    max-width: calc(100% + calc(var(--course-listing-padding) * 2));
    width: calc(100% + calc(var(--course-listing-padding) * 2));
    margin-left: calc(-1 * var(--course-listing-padding));
  }
}

.course-listing-item,
.course-listing {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 1100px) {
  .course-listing-wrapper.css-grid {
    display: grid;
    grid-column-gap: var(--css-grid-grid-column-gap);

    .course-listing-item,
    .course-listing {
      margin-bottom: var(--css-grid-grid-column-gap);
    }
  }
}

@media only screen and (min-width: 1400px) {
  .course-listing-wrapper.css-grid {
    grid-column-gap: var(--css-grid-grid-column-gap--large);

    .course-listing-item,
    .course-listing {
      margin-bottom: var(--css-grid-grid-column-gap--large);
    }
  }
}

.section-instructors {
  img {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

/*
  CHECKOUT
  -----------------------------------------------------------------------------
*/

.custom {
  .checkout-submit-button {
    padding: 20px 30px 20px 30px;
    color: #fff;
    font-family: var(--font-sans-serif);
    font-size: 1.2rem;
    background-color: var(--red);
  }
}

/*
  VIDEO CAROUSEL
  -----------------------------------------------------------------------------
*/

.video-list-item-text {
  line-height: 1.2;
}
