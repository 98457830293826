@media only screen and (max-width: 1023px) {
  ._navigation {
    z-index: var(--navigation-z-index);

    .mobile-spacer {
      flex-grow: 1;
    }
    .navigation-mobile-cart {
      display: flex;
      align-items: center;
      padding-left: 8px;
      padding-right: 8px;
      margin-right: 12px;
    }
    /* Sections ------------------------------------------------------------- */
    .navigation-inner {
      overflow: hidden;
      position: relative;
    }
    .navigation-menu {
      display: flex;
      flex-direction: column;
      background-color: #efefef;
      position: fixed;
      overflow: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: var(--navigation-item-child-font-size);

      .navigation-menu-header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background-color: var(--navigation-menu-header-bg);
        width: 100%;
        min-height: calc(
          var(--navigation-logo-height) + var(--navigation-logo-p-y) +
            var(--navigation-logo-p-y)
        );
      }
    }
    .navigation-left {
      position: sticky;
      top: 0;
      display: flex;
      background-color: var(--navigation-bg-color);
    }
    .navigation-center-wrapper {
      padding-top: 16px;
      flex-grow: 1;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .navigation-center,
    .navigation-right,
    .navigation-menu-header {
      width: 100%;
    }
    .navigation-center {
      border-top: var(--navigation-item-border);
      max-height: 100%;
      overflow: auto;
    }
    .navigation-right {
      display: flex;
      justify-content: space-between;
      bottom: 0;
      background-color: #d9d9d8;
      padding: 24px;
    }
    .navigation-menu,
    .nav-group {
      transform: translateX(110%);
      visibility: hidden;
      transition:
        transform 0.5s cubic-bezier(0.32, 0.72, 0, 1),
        visibility 0.5s cubic-bezier(0.32, 0.72, 0, 1);
    }
    /* Top level items ----------------------------------------------------- */

    .navigation-center a,
    .navigation-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: var(--navigation-item-border);
      padding: var(--navigation-item-p);
      color: var(--navigation-menu-text-color);
      text-align: left;
      background: var(--navigation-item-bg-color);
      cursor: pointer;
    }
    .navigation-right a,
    .navigation-right button {
      display: flex;
      padding: 10px 18px;
      background-color: var(--navigation-btn-bg);
      color: white;
      font-weight: 600;
    }
    .navigation-menu-home {
      display: flex;
      align-items: center;
      padding-right: 12px;
      padding-left: 24px;
    }
    .navigation-menu-close {
      padding-left: 12px;
      padding-right: 24px;
    }
    /* Sub nav -------------------------------------------------------------- */

    .navigation-children {
      a,
      button,
      ul::before {
        padding: var(--navigation-item-child-p);
      }
    }
    .navigation-parent > span {
      display: none;
    }
    .navigation-parent > .navigation-item {
      border-top: var(--navigation-item-border);
    }
    .navigation-children {
      li[data-header] {
        & > button {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        & > a,
        span {
          display: none;
        }
        .no-children {
          display: flex;
        }
      }

      .nav-group {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        background: #fff;
        z-index: 100;

        ul::before {
          content: attr(title);
          display: block;
          color: #000;
          font-weight: bold;
        }
        a {
          border-bottom: none;
        }
      }
      .nav-group-header {
        height: 3em;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: #efefef;
        border-bottom: 1px solid #d9d9d8;
      }
      .nav-group-close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: black;
        width: 3em;
        padding: 0;
        font-weight: bold;
        position: relative;
        z-index: 100;
        border-right: 1px solid #d9d9d8;
      }
      .landing-page-link {
        width: max-content;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        letter-spacing: 0.05em;
      }
      .landing-page-link span {
        position: relative;
        pointer-events: none;
      }
      .landing-page-link span::after {
        content: "";
        height: 2px;
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        background-color: var(--navigation-menu-text-color);
      }
      li[aria-expanded="true"] + .nav-group {
        visibility: visible;
        opacity: 1;
        transform: none;
      }
    }
    /* Nav Toggle ----------------------------------------------------------- */

    .navigation-mobile-toggle {
      display: flex;
      align-items: center;
      padding-right: 16px;
      color: var(--navigation-text-color);
    }

    /* Logo ----------------------------------------------------------------- */

    .navigation-logo {
      border-bottom: 0;
      padding-left: var(--navigation-logo-p-l);
    }

    /* Active --------------------------------------------------------------- */

    &.navigation-active {
      .navigation-inner {
        height: 100%;
      }

      .navigation-right,
      .navigation-center-wrapper {
        position: relative;
      }
      .navigation-menu,
      .navigation-menu-header {
        transform: none;
        visibility: visible;
        transition: transform 0.5s cubic-bezier(0.32, 0.72, 0, 1);
      }

      :global(.lines) {
        display: none;
      }
    }
  }
}
