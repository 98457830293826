// TODO: remove?
$grid-start: 1100px;
$grid-medium: 750px;
$grid-widescreen: 1400px;
// TODO: used in one place:
$grid-none: 1099px;

:root {
  --body-bg: #fff;
  --body-text-color: #000;
  --body-leading: 1.5;
  --body-min-h: 100vh;
  --body-font-family: "serif";

  --h1-font-family: "sans-serif";
  --h2-font-family: "sans-serif";
  --h3-font-family: "sans-serif";
  --h4-font-family: "sans-serif";

  --h1-text-transform: uppercase;
  --h2-text-transform: uppercase;
  --h3-text-transform: uppercase;
  --h4-text-transform: uppercase;

  --heading-line-height: 1.1;

  /* Default padding for slices */
  --slice-pt--small: 20px;
  --slice-pb--small: 20px;
  --slice-pt--large: 40px;
  --slice-pb--large: 40px;

  /*
    CSS GRID
    ---------------------------------------------------------------------------
  */

  --css-grid-grid-column-gap: 2.75%;
  --css-grid-grid-column-gap--large: 4%;

  /*
    WRAPPER
    ---------------------------------------------------------------------------
  */

  --wrapper-px: 2rem;
  --wrapper-max-w--large: 1440px;

  @media only screen and (max-width: 550px) {
    --wrapper-px: 1.25rem;
  }

  /*
    BUTTON
    ---------------------------------------------------------------------------
  */

  --button-border-w: 2px;
  --button-border-color: #333;
  --button-border-radius: 6px;
  --button-pt: 0;
  --button-pr: 30px;
  --button-pb: 0;
  --button-pl: 30px;
  --button-font-family: var(--body-font-family);
  --button-font-size: 20px;
  --button-font-weight: 400;
  --button-text-transform: none;
  --button-bg: #333;

  /*
    HERO
    ------------------------------------------------------------------------------
  */

  --hero-pt: 35px;
  --hero-pb: 35px;
  --hero-pt--medium: 60px;
  --hero-pb--medium: 60px;
  --hero-text-pl--medium: 66px;
  --hero-text-pl--large: 60px;
  --hero-heading-color: inherit;

  /*
    FOOTER
    ------------------------------------------------------------------------------
  */

  --footer-text-color: #000;
  --footer-link-color: #000;
  --footer-font-family: var(--body-font-family);
  --footer-font-size: 15px;
  --footer-link-text-decoration: underline;
  --footer-bg: #fff;

  /*
    RICH TEXT
    ------------------------------------------------------------------------------
  */

  --rte-font-family: var(--body-font-family);
  --rte-body-mb: 1rem;
  --rte-link-color: #000;
  --rte-link-text-decoration: underline;

  --rte-hr-my: var(--rte-body-mb);

  --rte-h1-mb: var(--rte-body-mb);
  --rte-h2-mb: var(--rte-body-mb);
  --rte-h3-mb: var(--rte-body-mb);
  --rte-h4-mb: var(--rte-body-mb);

  --rte-h1-font-family: var(--h1-font-family);
  --rte-h2-font-family: var(--h2-font-family);
  --rte-h3-font-family: var(--h3-font-family);
  --rte-h4-font-family: var(--h4-font-family);

  --rte-h1-text-transform: var(--h1-text-transform);
  --rte-h2-text-transform: var(--h2-text-transform);
  --rte-h3-text-transform: var(--h3-text-transform);
  --rte-h4-text-transform: var(--h4-text-transform);

  --rte-hr-color: #333;

  /*
    PLAY BUTTON
    ------------------------------------------------------------------------------
  */

  --play-button-size: 86px;
  --play-button-arrow-color: #333;
  --play-button-bg: #fff;

  /*
    VIDEO CAROUSEL
    ---------------------------------------------------------------------------
  */

  --video-list-item-font-family: var(--body-font-family);

  --video-list-item-link-p: 16px;
  --video-list-item-link-color: #333;
  --video-list-item-link-color-hover: #000;
  --video-list-item-link-font-size: 15px;
  --video-list-item-link-text-decoration: none;
  --video-list-item-link-border-t: none;
  --video-list-item-link-border-r: none;
  --video-list-item-link-border-b: none;
  --video-list-item-link-border-l: none;

  --video-list-item-link-color--active: #000;
  --video-list-item-link-border-l--active: none;

  /*
    EXPANDER
    ----------------------------------------------------------------------------
  */

  --expander-icon-bg: #000;

  /*
    ACCORDION
    ----------------------------------------------------------------------------
  */

  --accordion-heading-mb: 1rem;
  --accordion-heading-font-family: var(--h2-font-family);

  --accordion-title-mb: 0.5rem;
  --accordion-title-border-t: 0;
  --accordion-title-border-b: 0;
  --accordion-title-p: 1.25rem 3rem 1.25rem 1.5rem;
  --accordion-title-font-size: 17px;
  --accordion-title-font-weight: normal;
  --accordion-title-bg: transparent;

  --accordion-body-p: 1rem 1.5rem 3rem 1.5rem;

  /*
    TESTIMONIAL
    ----------------------------------------------------------------------------
  */

  --testimonial-wrapper-mb: 1.5rem;
  --testimonial-max-w: 450px;
  --testimonial-blockquote-color: #222;
  --testimonial-blockquote-font-size: 22px;

  --testimonial-avatar-max-w: 136px;

  --testimonial-cite-text-color: #555;
  --testimonial-cite-font-style: normal;
  --testimonial-cite-font-size: 18px;

  /*
    COHORT LISTING
    ----------------------------------------------------------------------------
  */

  --cohort-listing-font-family: var(--body-font-family);
  --cohort-listing-filters-text-color: #000;
  --cohort-listing-expander-text-color: #000;
  --cohort-listing-expander-border-t: 1px solid rgba(255, 255, 255, 0.35);
  --cohort-listing-expander-border-b: 1px solid rgba(255, 255, 255, 0.35);
  --cohort-listing-expander-icon-color: #666;
  --cohort-listing-filter-button-bg-color-active: #eee;
  --cohort-listing-filter-button-border-focus: 2px solid #000;

  /*
    COMPARISON
    ---------------------------------------------------------------------------
  */

  --comparison-item-font-family: var(--body-font-family);
  --comparison-item-font-size: var(--body-font-size);
  --comparison-item-heading-font-family: var(--h4-font-family);
  --comparison-item-heading-price-font-family: var(font-sans-serif);
  --comparison-item-heading-text-color: white;
}
