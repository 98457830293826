.custom-field {
  --rotate-default: 180deg;
  --checkmark-radius: 4px;
  --border-size: 1px;
  --input-size: 22px;
  --radio-check-size: 12px;
  --guter: calc(var(--input-size) + 5px);
  --inner-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.custom-field {
  cursor: pointer;
  position: relative;
}

.custom-field > input[type="checkbox"],
.custom-field > input[type="radio"] {
  opacity: 0;
  width: 3px;
  height: 3px;
  z-index: 2;
}

.custom-field-label {
  display: block;
  padding-left: var(--guter);
  color: #333;
}

.custom-field-el {
  position: relative;
  content: "";
  display: block;
  width: var(--input-size);
  height: var(--input-size);
  border: var(--border-size) solid var(--kup-element-border-color);
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0deg) scale(1);
  background: var(--kup-element-bg-color);
  box-shadow: var(--inner-shadow);
  transition: box-shadow 0.1s ease-out;
}

.custom-field-checkbox .custom-field-el {
  border-radius: var(--checkmark-radius);
}
.custom-field-radio .custom-field-el,
.custom-field-radio .custom-field-el::after {
  border-radius: 100%;
}

.custom-field-radio .custom-field-el::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--radio-check-size);
  height: var(--radio-check-size);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-field-checkbox .custom-field-el::before {
  opacity: 0;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 1px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjE1MzQgMTBDNC4yODU2OCAxMCA0LjA2NTkgOS42NTczMiAzLjg0NjEyIDkuNDI4ODdMMC4zMjk2NjcgNS44ODc4OUMtMC4xMDk4ODkgNS40MzA5OSAtMC4xMDk4ODkgNC42MzE0MiAwLjMyOTY2NyA0LjE3NDUyQzAuNzY5MjI0IDMuNzE3NjIgMS40Mjg1NiAzLjcxNzYyIDEuODY4MTIgNC4xNzQ1Mkw0LjUwNTQ2IDYuODAxN0w5Ljg5MDAyIDAuNDA1MDg4QzEwLjMyOTYgLTAuMDUxODEyOCAxMC45ODg5IC0wLjE2NjAzOCAxMS40Mjg1IDAuMjkwODYzQzExLjg2OCAwLjc0Nzc2MyAxMS45Nzc5IDEuNDMzMTEgMTEuNTM4NCAxLjg5MDAxTDUuMzg0NTcgOS40Mjg4N0M1LjI3NDY4IDkuNjU3MzIgNC45NDUwMSAxMCA0LjYxNTM0IDEwWiIgZmlsbD0iIzMzMzMzMyIvPgo8L3N2Zz4K);
}

.custom-field-checkbox
  > input:checked
  + .custom-field-label
  .custom-field-el::before {
  opacity: 1;
}

.custom-field-radio
  > input:checked
  + .custom-field-label
  .custom-field-el::after {
  background-color: #333;
}
