@import "./animations";

// needs to sit on top of Hubspot chat bubble
body div#hs-eu-cookie-confirmation {
  z-index: 3000000000 !important;
}

html,
body {
  color: var(--body-text-color);
  background-color: var(--body-bg);
}

body {
  min-height: var(--body-min-h);
  line-height: var(--body-leading);
}

#main-content {
  outline: none;
}

.dev-mode {
  display: none !important;
}

.slice-padding {
  padding-top: var(--slice-pt--small);
  padding-bottom: var(--slice-pb--small);
}
@media only screen and (min-width: 1280px) {
  .slice-padding {
    padding-top: var(--slice-pt--large);
    padding-bottom: var(--slice-pb--large);
  }
}

h1,
h2,
h3,
h4 {
  line-height: var(--heading-line-height);
}

.top-50 {
  top: 50%;
}
.right-50 {
  right: 50%;
}
.bottom-50 {
  bottom: 50%;
}
.left-50 {
  left: 50%;
}

/* IE 11 support */
.translate-center {
  transform: translate(-50%, -50%);
}

/*
  WRAPPER
  ------------------------------------------------------------------------------
  Element for horizontal spacing of slices
  Should be direct decendent of .theme-*
*/

.wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--wrapper-px);
  padding-left: var(--wrapper-px);
}

@media only screen and (min-width: 1440px) {
  .wrapper {
    max-width: var(--wrapper-max-w--large);
  }
}

/*
  SKIP TO CONTENT
  ------------------------------------------------------------------------------
*/

.skip-to-content {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  clip: rect(1px, 1px, 1px, 1px);
  color: #000 !important;
  background-color: #fff !important;
  z-index: 9999;
}

.skip-to-content:focus {
  width: auto;
  height: auto;
  clip: auto;
}

/*
  BUTTON
  ---------------------------------------------------------------------------
*/

.button {
  display: inline-block;
  border-radius: var(--button-border-radius);
  border-color: var(--button-border-color);
  border-width: var(--button-border-w);
  border-style: solid;
  padding-top: var(--button-pt);
  padding-right: var(--button-pr);
  padding-bottom: var(--button-pb);
  padding-left: var(--button-pl);
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  text-align: center;
  text-decoration: none;
  text-transform: var(--button-text-transform);
  background-color: var(--button-bg);
}

/*
  CSS GRID
  ------------------------------------------------------------------------------
*/

.grid-mb-on-mobile > *,
.css-grid-mb-on-mobile > * {
  margin-bottom: 2rem;
}

.grid-mb-on-mobile > *:last-child,
.css-grid-mb-on-mobile > *:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1100px) {
  .grid-mb-on-mobile > *,
  .css-grid-mb-on-mobile > * {
    margin-bottom: 0;
  }

  .grid-cols-span-full {
    grid-column: 1 / -1;
  }
}

/*
  RESPONSIVE VIDEO
  ------------------------------------------------------------------------------

  .responsive-video
    iframe.absolute.inset-0
*/

.responsive-video {
  position: relative;
  padding-top: 56.25%;
}

/*
  EXPANDER
  ----------------------------------------------------------------------------
*/

.expander {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: left;
}

.expander::after,
.expander::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  background-color: var(--expander-icon-bg);
}

.expander::after {
  width: 15px;
  height: 3px;
}

.expander::before {
  width: 3px;
  height: 15px;
  right: calc(1.5rem + 6px);
}

.expander.active::before {
  display: none;
}

/*
  ACCORDION
  ------------------------------------------------------------------------------

  .accordion
    .accordion-heading (h1,h2,h3, etc.)
    dl
      dt.expander
      dd.rte[.hidden]
*/

.accordion {
  .expander {
    margin-bottom: var(--accordion-title-mb);
    border: 0;
    border-top: var(--accordion-title-border-t);
    border-bottom: var(--accordion-title-border-b);
    padding: var(--accordion-title-p);
    font-size: var(--accordion-title-font-size);
    font-weight: var(--accordion-title-font-weight);
    background-color: var(--accordion-title-bg);
  }

  dd {
    margin-left: 0;
    padding: var(--accordion-body-p);
  }
}

.accordion-heading {
  margin-bottom: var(--accordion-heading-mb);
  font-family: var(--accordion-heading-font-family);
}

/*
  HERO
  ------------------------------------------------------------------------------
*/

.hero {
  padding-top: var(--hero-pt);
  padding-bottom: var(--hero-pb);
}

.hero h1 {
  color: var(--hero-heading-color);
}

@media only screen and (min-width: 1000px) {
  .hero {
    display: flex;
    padding-top: var(--hero-pt--medium);
    padding-bottom: var(--hero-pt--medium);
  }

  .hero > div {
    width: 50%;
  }

  .hero > div.text {
    order: 2;
    padding-left: var(--hero-text-pl--medium);
  }
}

@media only screen and (min-width: 1199px) {
  .hero > div.text {
    order: 2;
    padding-left: var(--hero-text-pl--large);
  }
}

/*
  RICH TEXT
  ------------------------------------------------------------------------------
*/

.rte p.block-img:last-of-type {
  margin: 0;
}

.rte ol,
.rte ul,
.rte p,
.rte li {
  margin-bottom: var(--rte-body-mb);
}

.rte a:not(.button) {
  color: var(--rte-link-color);
  text-decoration: var(--rte-link-text-decoration);
}

.rte h1 {
  margin-bottom: var(--rte-h1-mb);
  font-family: var(--rte-h1-font-family);
  text-transform: var(--rte-h1-text-transform);
}

.rte h2 {
  margin-bottom: var(--rte-h2-mb);
  font-family: var(--rte-h2-font-family);
  text-transform: var(--rte-h2-text-transform);
}

.rte h3 {
  margin-bottom: var(--rte-h3-mb);
  font-family: var(--rte-h3-font-family);
  text-transform: var(--rte-h3-text-transform);
}

.rte h4 {
  margin-bottom: var(--rte-h4-mb);
  font-family: var(--rte-h4-font-family);
  text-transform: var(--rte-h4-text-transform);
}

.rte hr {
  margin: var(--rte-hr-my) 0;
  border: 0;
  height: 1px;
  background-color: var(--rte-hr-color);
}

/*
  PLAY BUTTON
  ------------------------------------------------------------------------------
*/

.playbutton {
  width: var(--play-button-size);
  height: var(--play-button-size);
}

.playbutton-arrow {
  fill: var(--play-button-arrow-color);
}

.playbutton-background {
  fill: var(--play-button-bg);
}

/*
  COMPARISON
  ------------------------------------------------------------------------------
*/

.comparison-slice {
  & > .css-grid > * {
    margin-bottom: 2rem;
  }
}

.comparison-item {
  background: #fff;
  box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.05);
}

.comparison-item-body {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  font-family: var(--comparison-item-font-family);

  li {
    border-bottom: 1px solid #b3b3b3;
    padding: 1rem 0;
    font-size: var(--comparison-item-font-size);
    font-family: var(--font-serif);
    text-align: center;
  }

  p {
    padding: 1rem 0;
  }

  li:first-of-type {
    border-top: 0;
  }
}

.comparison-actions {
  border-top: 1px solid #b3b3b3;
  margin-right: 1.75rem;
  margin-left: 1.75rem;
  padding: 3rem 0;
  text-align: center;
}

.comparison-item-heading {
  font-weight: 600;
  line-height: 1;
  text-align: center;
  padding: 0 1rem 0;

  div {
    padding: 1.5rem 0 0 0;
  }

  div:last-of-type {
    padding-bottom: 1.5rem;
  }
}

.comparison-item-heading-text {
  font-family: var(--comparison-item-heading-font-family);
  font-style: italic;
  color: var(--comparison-item-heading-text-color);
  font-size: 34px;
}

.comparison-item-heading-price {
  font-family: var(--comparison-item-heading-price-font-family);
  color: var(--comparison-item-heading-text-color);
  font-size: 24px;
}

@media only screen and (min-width: 1280px) {
  .comparison-item-heading {
    div {
      padding: 2rem 0 0 0;
    }

    div:last-of-type {
      padding-bottom: 2rem;
    }
  }

  .comparison-item-body {
    margin-right: 1.75rem;
    margin-left: 1.75rem;

    li {
      padding: 1.3rem 0;
      font-size: 1.3rem;
    }

    p {
      padding: 1.3rem 0;
    }
  }
}

/*
  TESTIMONIAL
  ------------------------------------------------------------------------------
*/

.testimonial-wrapper {
  margin-bottom: var(--testimonial-wrapper-mb);

  .responsive-video {
    margin-bottom: 1rem;
  }
}

.testimonial {
  max-width: var(--testimonial-max-w);
  margin-right: auto;
  margin-left: auto;
}

.testimonial blockquote {
  color: var(--testimonial-blockquote-color);
  font-size: var(--testimonial-blockquote-font-size);
}

.testimonial .avatar {
  display: block;
  max-width: var(--testimonial-avatar-max-w);
  margin: 0 auto 1.5rem auto;
}

.testimonial cite {
  color: var(--testimonial-cite-text-color);
  font-size: var(--testimonial-cite-font-size);
  font-style: var(--testimonial-cite-font-style);

  &::before {
    content: "—";
  }
}

.testimonial-video {
  margin-bottom: 1rem;
}

.testimonial--no-video {
  text-align: center;
}

/*
  COHORT LISTING
  ------------------------------------------------------------------------------
*/

.cohort-listing {
  font-family: var(--cohort-listing-font-family);

  .expander {
    margin-bottom: 1.25rem;
    border-top: var(--cohort-listing-expander-border-t);
    border-bottom: var(--cohort-listing-expander-border-b);
    padding: 1.25rem 1.5rem 1.25rem 0;
    color: var(--cohort-listing-expander-text-color);
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    background: transparent;

    &::after,
    &::before {
      background: var(--cohort-listing-expander-icon-color);
    }
  }
}
