@use "sparkle-icon";
@use "wave-circle";

/*
  Simple animations to be applied to elements by class.
  For complex animations use the Motion library
*/

/*
  ------------------------------------------------------------------------------
  Fade In
  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*/

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animation-fade-in {
  animation: fadeIn 300ms ease-out;
  animation-fill-mode: forwards;
}

/*
  ------------------------------------------------------------------------------
  Slide and Fade In
  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*/

@keyframes slideAndFadeIn {
  0% {
    overflow: hidden;
    opacity: 0;
    top: -1rem;
  }
  100% {
    overflow: hidden;
    top: 0;
    opacity: 1;
    height: auto;
  }
}

.animation-slide-and-fade-in {
  animation: slideAndFadeIn 115ms ease-out;
  animation-fill-mode: forwards;
}

/*
  ------------------------------------------------------------------------------
  Splash In
  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*/

@keyframes splashIn {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animation-splash-in {
  animation: splashIn 200ms cubic-bezier(0.345, 0.115, 0.135, 1.62);
  animation-fill-mode: forwards;
}

/*
  ------------------------------------------------------------------------------
  Auth - Pulsing circle
  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*/

.pulsating-circle {
  position: relative;
  width: 32px;
  height: 32px;
}

.pulsating-circle svg {
  position: relative;
  z-index: 2;
}

.pulsating-circle:after,
.pulsating-circle:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 60px;
  background-color: rgba(209, 249, 170, 0.8);
  animation: pulse-ring 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
  animation-delay: 0.35s;
}

.pulsating-circle svg {
  animation: pulse-dot 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  20% {
    opacity: 0.5;
  }
  80%,
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}

/*
  ------------------------------------------------------------------------------
  Spinner
  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*/

:root {
  --spinner-color: #555;
  --spinner-size: 24px;
}

$spinner-speed: 0.65s;
$blade-count: 8;
$blade-rotation-increment: calc(360 / $blade-count);
$blade-animation-delay-increment: calc($spinner-speed / $blade-count);

.spinner {
  animation: spinner-spin 14s infinite linear;
}

.spinner > div {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.12em;
  height: 0.32em; /* .2777em */
  border-radius: 0.75em;
  background-color: transparent;
  transform-origin: center -0.2em; /* -0.2222em */
  animation: spinner-fade $spinner-speed infinite linear;

  $animation-delay: 0s;
  $blade-rotation: 0deg;

  @for $i from 1 through $blade-count {
    &:nth-child(#{$i}) {
      animation-delay: $animation-delay;
      transform: rotate($blade-rotation);
      $blade-rotation: $blade-rotation + $blade-rotation-increment;
      $animation-delay: $animation-delay + $blade-animation-delay-increment;
    }
  }
}

@keyframes spinner-fade {
  0% {
    background-color: var(--spinner-color);
  }

  100% {
    background-color: transparent;
  }
}

@keyframes spinner-spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulse-scale {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.025);
  }
  60% {
    transform: scale(1.025);
  }
  100% {
    transform: scale(1);
  }
}

/*
  ------------------------------------------------------------------------------
  Spinner
  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*/

@keyframes bounce {
  0%,
  to {
    transform: translateY(-15%);
    animation-timing-function: cubic-bezier(0.85, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.3, 1);
  }
}

.animation-bounce {
  animation: bounce 1s infinite;
}

/*
  ------------------------------------------------------------------------------
  Shine (add to cart in cart)
  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*/

:root {
  --shine-degree: 120deg;
  --shine-color: rgba(255, 255, 255, 0.7);
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );
}

.animation-shine {
  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--shine-effect);
    animation: shine 5s ease-in-out infinite;
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  30% {
    left: 100%;
    transition-property: left;
  }
  100% {
    left: 100%;
    transition-property: left;
  }
}
