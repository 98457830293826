.accordion-button {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 20px 44px 20px 24px;
  color: var(--blue-dark);
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  background-color: var(--gray-light);
}

.accordion-button::after,
.accordion-button::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  background-color: var(--expander-icon-bg);
}

.accordion-button::after {
  width: 15px;
  height: 3px;
}

.accordion-button::before {
  width: 3px;
  height: 15px;
  right: calc(1.5rem + 6px);
}

.accordion-button[aria-expanded="true"]::before {
  display: none;
}

.accordion-button:focus-within {
  outline: none;
  box-shadow: 0 0 0 4px var(--teal);
}

.accordion-panel {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.accordion-panel[data-expanded="true"] {
  margin-top: 1rem;
}
