/* mobile */
@use "./nav_mobile.scss";

/* desktop */
@use "./nav_desktop.scss";

._navigation {
  position: sticky;
  top: 0;
  color: var(--navigation-text-color);

  /* desktop and mobile - can be scoped to @media query */
  --navigation-font-family: var(--header-font-family);

  /* desktop and mobile - can be scoped to @media query */
  --navigation-font-size: 16px;

  /* spacing around the logo.
     desktop and mobile - can be scoped to @media query
     this will likely determine the height of the navigation.
     If this value is changed `--navigation-button-spacing-y` will
     likely need to be adjusted.
  */
  --navigation-logo-p-y: 8px;

  /* mobile variables */
  @media only screen and (max-width: 1023px) {
    --navigation-item-border: 1px solid #cecece;
    --navigation-item-p: 16px 24px;
    --navigation-item-bg-color: transparent;
    /* spacing around the logo. */
    --navigation-logo-p-l: 10px;
    --navigation-menu-text-color: #000;
    /* Nested items */
    --navigation-item-child-font-size: 18px;
    --navigation-item-child-p: var(--navigation-item-p);
    --navigation-menu-header-bg: #000;
    --navigation-btn-bg: transparent;
  }

  /* desktop variables*/
  @media only screen and (min-width: 1024px) {
    /* max width of sub menu */
    --navigation-children-max-width: 684px;

    /* spacing above and below buttons.
       by default, the .navigation-right container is align-items: stretch,
       so buttons will touch the top and bottom without spacing
    */
    --navigation-links-weight: 500;
    --navigation-button-spacing-y: 8px;
    --navigation-item-color-focus: #000;
    --navigation-item-text-decoration-focus: underline;
    --navigation-child-item-color: inherit;
    --navigation-child-item-color-hover: inherit;
    --navigation-category-size: 14px;
    --navigation-child-size: 16px;
    --navigation-category-lh: 18px;
    --navigation-child-lh: 18px;
  }
}

.navigation-logo {
  display: block;
  padding-top: var(--navigation-logo-p-y);
  padding-bottom: var(--navigation-logo-p-y);
  color: transparent;
}

.navigation-logo img {
  width: var(--navigation-logo-width);
  height: var(--navigation-logo-height);
}

.navigation-inner {
  font-family: var(--navigation-font-family);
  font-size: var(--navigation-font-size);
}
