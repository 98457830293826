html,
body,
#__next {
  min-height: 100dvh;
}

/*
  --------------------------------------------------------------------------
  .up-container

  A replacement for the Tailwind `container` utility class.
  The Tailwind class is jumpy at breakpoints and shows too much whitespace
  at specific sizes. up-container is more fluid.
  
  If changes are made here, they need to be replicated for the
  .navigation-inner element.
  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
*/

.up-container {
  @apply w-full;
  @apply mx-auto;
  @apply px-4;
}

@media (min-width: 1024px) {
  .up-container {
    @apply px-8;
  }
}

@media (min-width: 1536px) {
  .up-container {
    @apply px-0;
    max-width: calc(1536px - 8rem);
  }
}
