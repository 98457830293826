@use "discount-form";
@use "discount-line-item";
@use "line-item";
@use "money-table";
@use "summary-table";

:root {
  --checkout-font-monospace: Menlo, Monaco, Consolas, monospace;
  --checkout-forms-max-width: 450px;
}

@keyframes checkout-slide-in {
  0% {
    transform: translateY(-4rem);
  }
  to {
    transform: translateY(0);
  }
}

.checkout-slide-in {
  animation: checkout-slide-in 250ms ease-out;
  animation-fill-mode: forwards;
}

.checkout-layout {
  max-width: 830px;
}

.checkout-amount-type {
  font-variant: small-caps;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
  font-size: 14px;
  text-decoration: none !important;
  opacity: 0.7;
}

.checkout-label-total {
  font-size: 16px;
}

.checkout-amount-value {
  font-family: var(--checkout-font-monospace);
  font-size: 16px;
}

.checkout-amount-value s {
  opacity: 0.5;
}

.checkout-amount-percentage {
  font-size: 14px;
}

.checkout-amount-value-original {
  font-weight: normal;
}

#payment-section {
  border-top: 4px solid #222;
}

@media screen and (min-width: 630px) {
  .checkout-payment-section {
    display: grid;
    grid-template-columns: 1fr 370px 40px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 2rem;
    line-height: 1;
  }
}

@media screen and (min-width: 630px) {
  .checkout-payment-form {
    max-width: var(--checkout-forms-max-width);
  }
}

@media screen and (max-width: 629px) {
  .checkout-payment-form {
    padding-right: 1rem;
  }
}

/*
  Stripe
  ------------------------------------------------------------
*/

.checkout-stripe-form-group {
  will-change: opacity, transform;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.checkout-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/*
  Discount Items
  ------------------------------------------------------------
*/

.checkout-discount-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/*
  Info Form
  ------------------------------------------------------------
*/

.checkout-info-form {
  display: inline-block;
  padding: 1rem;
  transition: all 0.2s;
}

.checkout-info-form:focus-within {
  background: #f9f9f9;
}

.checkout-info-button {
  margin-right: 0.5rem;
}

.checkout-info-actions {
  padding-top: 0.5rem;
}

.checkout-submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.FormRow {
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.checkout-token {
  display: inline-block;
  max-width: 100%;
  border-radius: 20px;
  padding: 3px 8px;
  color: #40530e;
  background: #e2f2b8;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.checkout-token-error {
  border-radius: 20px;
  padding: 3px 8px;
  color: #800;
  background: #ffd9d9;
}

.profile-select-item {
  margin-bottom: 0.5rem;
}

.profile-select-button {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  text-align: left;
  cursor: pointer;
}

.profile-select-email {
  opacity: 0.65;
  padding-top: 0.25rem;
}

.profile-select-name {
  font-weight: 500;
}

.profile-select-name span {
  border-bottom: 1px solid #ccc;
}

.profile-select-button:hover {
  background-color: #eee;
}

.profile-select-button:hover .profile-select-email {
  opacity: 1;
}

@keyframes check-fade-in {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animation-check-circle {
  opacity: 0;
  transform: scale(1.5);
  animation: check-fade-in 0.5s forwards;
}
