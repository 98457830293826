/* Desktop */
@media screen and (min-width: 630px) {
  .checkout-total-table {
    min-width: 280px;
    display: grid;
    grid-template-areas:
      "a a b"
      "c c d";
    grid-template-columns: 1.5fr 1fr 75px;
    grid-gap: 0.5rem;
    text-align: right;
    line-height: 1;
  }

  .checkout-total-table > * {
    align-self: end;
  }

  .checkout-total-table .checkout-amount-type {
    text-align: left;
  }
}

@media screen and (max-width: 629px) {
  .checkout-total-table {
    padding-left: 1rem;
  }
}
