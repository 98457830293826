.star-1 {
  animation: starPulse 1500ms cubic-bezier(0.345, 0.115, 0.135, 1.22);
  animation-iteration-count: infinite;
  transform-origin: 180px 40px;
}

.star-2 {
  animation: starPulse 1750ms cubic-bezier(0.345, 0.115, 0.135, 1.42);
  animation-delay: 300ms;
  animation-iteration-count: infinite;
  transform-origin: 220px 88px;
}

.star-3 {
  animation: starGlow 2500ms linear;
  animation-iteration-count: infinite;
}

@keyframes starPulse {
  0% {
    opacity: 0.75;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.8;
    transform: scale(0.5);
  }
}

@keyframes starGlow {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
