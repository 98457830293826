.theme-white {
  .cohort-listing .expander {
    border-bottom: 1px solid #ccc;
  }
}

.cohort-card {
  gap: 1.25rem;
  text-align: center;
  padding: 1rem;
}

.cohort-card {
  p {
    opacity: 0.7;
  }
}

.cohort-card-college-credit {
  box-shadow: 0 0 0 2px var(--teal);
}

.cohort-card-program-name {
  font-family: var(--font-serif) !important;
  font-size: 24px;
}

.cohort-card-action {
  align-items: flex-end;
  justify-content: center;
}

.cohort-card-dates {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.cohort-list-filter-option > span::after {
  border-radius: 0.25rem;
}
