@media (min-width: 1280px) {
  .video-list-item-button {
    border-top-width: 1px;
    border-left-width: 4px;
  }
}

[data-theme="white"] {
  .video-list-item-button {
    border-top-color: rgba(0, 0, 0, 0.15);
    border-left-color: transparent;
  }

  .video-list-item-button.selected {
    border-left-color: var(--blue-medium);
  }
}
