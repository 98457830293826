@use "error-message";
@use "custom-radio-checkbox";

:root {
  --kup-element-border-color: #888;
  --kup-element-focus-border-color: #555;
  --kup-element-bg-color: #fff;
  --kup-element-padding: 6px 6px;
  --kup-element-font-size: 16px;
}

.up-form-container {
  width: 100%;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 740px;
}

.kup-element {
  position: relative;
}

.kup-fieldset-checkbox-list .kup-element {
  margin-bottom: 16px;
}

.kup-label-text {
  display: block;
  margin-bottom: 8px;
  color: #111;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
}

.kup-textarea,
.kup-select,
.kup-input-text {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid var(--kup-element-border-color);
  border-radius: 3px;
  padding: var(--kup-element-padding);
  font-size: var(--kup-element-font-size);
  font-weight: 500;
  background-color: var(--kup-element-bg-color);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition: box-shadow 0.1s ease-out;
}

.kup-button:focus,
.kup-input-text:focus,
.kup-select:focus,
.kup-textarea:focus,
input[type="radio"]:focus + .custom-field-label .custom-field-el,
input[type="checkbox"]:focus + .custom-field-label .custom-field-el {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  border-color: var(--kup-element-focus-border-color);
}

.kup-element-has-errors .kup-input-text,
.kup-element-has-errors .kup-textarea,
.kup-element-has-errors .kup-select {
  border-color: #dd7777;
}

.kup-select-year {
  max-width: 160px;
}

.kup-input-text-zipcode {
  max-width: 200px;
}

.kup-input-text-gpa {
  max-width: 100px;
}

.kup-input-text-phone {
  max-width: 300px;
}

// TODO: remove?
.kup-dialing-code {
  font-size: var(--kup-element-font-size);
  padding: var(--kup-element-padding);
  padding-right: 10px;
  padding-left: 20px;
  opacity: 0.75;
  cursor: default;
}

.kup-select-arrow {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAwLjVIMEw2LjUgOEwxMyAwLjVaIiBmaWxsPSIjNDQ0NDQ0Ii8+Cjwvc3ZnPgo=);
  background-position:
    right 0.8em top 50%,
    0 0;
  background-repeat: no-repeat, repeat;
  background-size:
    0.7em auto,
    100%;
}

.kup-select-arrow::-ms-expand {
  display: none;
}

.kup-radio,
.kup-checkbox {
  flex-shrink: 0;
}

.kup-radio-label:not(:last-child) {
  margin-bottom: 10px;
}

.kup-radio-label,
.kup-checkbox-label {
  display: flex;
  align-items: baseline;
}

.kup-radio-label-text,
.kup-checkbox-label-text {
  font-size: 16px;
  line-height: 1.3;
}

.kup-help-text {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.2;
  opacity: 0.75;
}

.kup-label + .kup-help-text {
  margin-top: -8px;
}

.kup-fieldset {
  border: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

/* Rich Text
/* ------------------------------------------------------------------ */

.kup-rich-text {
  margin-bottom: 30px;
  font-size: 16px;
}

.kup-rich-text p {
  margin-bottom: 10px;
  line-height: 1.4;
  opacity: 0.7;
}

.kup-rich-text a {
  color: #444;
  text-decoration: underline;
}

.kup-complete-message {
  border-top: 1px solid var(--kup-element-border-color);
  padding-top: 20px;
  font-size: 20px;
}

.kup-link {
  color: #444;
  text-decoration: underline;
}

.kup-program {
  left: -10px;
  width: calc(100% + 20px);
  margin-bottom: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition:
    padding 0.4s ease-out,
    border 0.2s ease-in-out;
}

.kup-program .kup-element .kup-help-text {
  padding-left: 52px;
}

.kup-program .custom-field .custom-field-label .custom-field-el {
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.kup-program .custom-field.custom-field-radio .custom-field-label::after {
  top: 50%;
  transform: translateY(-50%);
}

.kup-program
  .custom-field.custom-field-radio
  > input:checked
  + .custom-field-label::after {
  left: 25px;
}

.kup-program .kup-checkbox-label,
.kup-program .kup-radio-label {
  min-height: 22px; /* radio / checkbox height */
  margin-bottom: 0;
  padding: 3px 20px;
}

.kup-program .kup-radio-label {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 8px;
  padding: 8px 20px;
  line-height: 1;
  transition:
    background 0.1s ease-out,
    border 0.2s ease-in-out;
}

.kup-program-selected {
  margin-bottom: 16px;
  border-color: #999;
  outline: 3px solid #eee;
}

.kup-program .custom-field-label {
  line-height: 1;
}

.kup-program.kup-program-selected .kup-checkbox-label {
  margin-bottom: 8px;
}

.kup-program.undecided.kup-program-selected {
  background-color: #ffffe4;
}
.kup-program.undecided.kup-program-selected .kup-checkbox-label {
  margin-bottom: 0;
}

.kup-program > .kup-element {
  margin-bottom: 0;
}

.kup-program > .kup-element .kup-label-required-flag {
  display: none;
}

.kup-program .kup-radio-label-checked {
  /* border-color: #76bd4f; */
  background: #ceecce;
}

.kup-program > .kup-checkbox-label {
  font-weight: 500;
}

/* Navigation
/* ------------------------------------------------------------------ */

.kup-navigation {
  padding-top: 10px;
  padding-bottom: 60px;
}

.kup-navigation .kup-button + .kup-button {
  margin-left: 10px;
}

/* Buttons
/* ------------------------------------------------------------------ */

.kup-button {
  position: relative;
  border: 0;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 20px;
  text-decoration: none !important;
  cursor: pointer;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s ease-out;
}

.kup-button:active {
  top: 1px;
  box-shadow: none;
}

.kup-button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

/* Uploading Message
/* ------------------------------------------------------------------ */

.kup-file-uploading-message {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  background: #f3f3f3;
}

/* Thanks Stamp
/* ------------------------------------------------------------------ */

.kup-app-stamp {
  top: 48px;
  right: 16px;
  width: 233px;
  height: 118px;
  opacity: 0;
}

@media only screen and (max-width: 615px) {
  .kup-app-stamp {
    display: none;
  }
}

.kup-app-stamp-text {
  color: #3e8c1a;
  line-height: 44px;
}

.kup-app-stamp-image {
  width: 233px;
}
