.cohort-list-filter-option {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.cohort-list-filter-option > span::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
  transition:
    transform 200ms cubic-bezier(0.345, 0.115, 0.135, 1.42),
    opacity 150ms ease-out;
  opacity: 0;
  transform: scale(0.8);
}

.cohort-list-filter-option input {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip-path: polygon(0 0);
}

.cohort-list-filter-option span > span {
  position: relative;
  z-index: 2;
}

.cohort-list-filter-option input:focus + span::after {
  border: 1px solid rgba(0, 0, 0, 0.8);
  transform: scale(1.05);
  opacity: 1;
}

.cohort-list-filter-option input:checked + span::after {
  transform: scale(1.05);
  opacity: 1;
  background: #ddd;
}

.cohort-list-filter-option input:checked + span span {
  color: #222;
}
