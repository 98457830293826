@media only screen and (min-width: 1024px) {
  ._navigation {
    z-index: var(--navigation-z-index);

    .navigation-mobile-cart {
      display: none;
    }

    .navigation-desktop-cart {
      margin-left: 12px;
    }

    background-color: var(--navigation-bg-color);
    .navigation-inner {
      display: flex;
      padding-right: 48px;
      padding-left: 48px;
      align-items: center;
    }
    .navigation-menu,
    .navigation-center-wrapper {
      display: contents;
    }
    .navigation-mobile-toggle,
    .nav-group-header,
    .navigation-menu-header,
    .navigation-arrow {
      display: none;
    }

    /* Navigation Left (logo) ----------------------------------------------- */

    .navigation-left {
      flex-shrink: 0;
    }

    /* Navigation Right (buttons) ------------------------------------------- */

    .navigation-right {
      display: flex;
      align-items: stretch;
      flex-shrink: 0;
      padding-top: var(--navigation-button-spacing-y);
      padding-bottom: var(--navigation-button-spacing-y);

      button,
      a {
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }

    /* Navigation Center (links) -------------------------------------------- */

    .navigation-center {
      display: flex;
      flex-grow: 1;
      justify-content: space-around; /* IE 11 */
      justify-content: space-evenly;
      align-self: stretch;
      flex-wrap: wrap;

      .navigation-chevron {
        margin-left: 0.25rem;
        display: inline-block;
      }

      li {
        position: relative;
        display: flex;
        align-content: center;
      }

      a {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      a,
      span {
        font-weight: var(--navigation-links-weight);
      }
      a:focus,
      a:hover {
        text-decoration: var(--navigation-item-text-decoration-focus);
      }
    }

    .navigation-center a,
    .navigation-parent {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .navigation-parent > a {
      position: relative;
      padding-right: 1.2rem;
    }

    /* Navigation Children Container ---------------------------------------- */

    .navigation-children {
      list-style: none;
      overflow: hidden;
      position: absolute;
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px 40px;
      max-height: 526px;
      left: -1rem;
      top: 90%;
      background-color: #fff;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      clip-path: inset(100% 0 0 0);
      width: max-content;
      padding: 32px;

      --scrollWidth: max-content;

      .nav-group,
      .nav-group > ul {
        display: contents;
      }

      li {
        width: 180px;
        margin: 0;
        padding: 0;
        font-size: var(--navigation-child-size);
        line-height: var(--navigation-child-lh);
      }

      li[data-header] {
        & > a,
        span {
          font-weight: bold;
          font-size: var(--navigation-category-size);
          line-height: var(--navigation-category-lh);
        }
        & > button {
          display: none;
        }
      }
      .nav-group li a {
        font-weight: normal;
      }
      .nav-group li:last-child {
        margin-bottom: 16px;
      }

      a,
      span {
        display: block;
        width: 100%;
        max-width: var(--navigation-children-max-width);
        padding: 0;
        color: var(--navigation-child-item-color);
      }

      a:hover {
        color: var(--navigation-child-item-color-hover);
      }
    }

    /* hover with mouse, focus with keyboard, etc. */
    .navigation-parent:focus-within .navigation-children,
    .navigation-parent:focus .navigation-children,
    .navigation-parent:hover .navigation-children {
      width: var(--scrollWidth);
      max-width: var(--navigation-children-max-width);
      clip-path: none;
      height: max-content;
    }
  }
}
