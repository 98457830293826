.wave-circle {
  animation: wave-circle 7000ms linear;
  animation-iteration-count: infinite;
  transform-origin: center;
}

@keyframes wave-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
