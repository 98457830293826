@tailwind base;
@tailwind components;
@tailwind utilities;

@import "up-styles";

@import "./KUP/variables";
@import "./KUP/defaults";
@import "./custom";
@import "./accordion";
@import "./cohort-list";
@import "./video-carousel";
