.checkout-list-item {
  position: relative;
  border-bottom: 1px dashed #ccc;
  outline: 1px solid transparent;
  outline-offset: -8px;
  transition: all 350ms cubic-bezier(0.345, 0.115, 0.135, 1.3);
}

.checkout-list-item-content {
  position: relative;
  z-index: 2;
  transition: padding 350ms cubic-bezier(0.345, 0.115, 0.135, 1.3);
}

.checkout-list-item.confirm-removal {
  border-radius: 2px;
  border-bottom: none;
  outline: 1px solid #f45050;
  outline-offset: 0;
}

.checkout-list-item.change-date {
  border-radius: 2px;
  border-bottom: none;
  padding: 12px;
  outline: 1px solid rgb(59 130 246);
  outline-offset: 0;
}

.checkout-list-item-delete-confirmation {
  grid-column: span 3;
}

.checkout-list-item-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.15;
}

.checkout-list-item-dates {
  opacity: 0.7;
  font-size: 14px;
}

.checkout-list-item:not(:first-child) {
  padding-top: 1rem;
}

/*
  List Items (desktop)
  ------------------------------------------------------------
*/

@media screen and (min-width: 630px) {
  .checkout-list-item-content {
    display: grid;
    grid-template-areas:
      "a a a"
      "b c d"
      "e c g";
    grid-template-columns: 1fr auto 40px;
  }

  .checkout-list-item-actions {
    opacity: 0.7;
    grid-area: e;
  }

  .checkout-list-item-dates {
    grid-area: a;
    padding-bottom: 0.25rem;
  }

  .checkout-list-item-title {
    grid-area: b;
    padding-right: 4rem;
  }

  .checkout-list-item-money {
    grid-area: c;
  }

  .checkout-list-item-remove {
    grid-area: d;
    display: flex;
    justify-content: right;
  }
}

/*
  List Items (mobile)
  ------------------------------------------------------------
*/

@media screen and (max-width: 629px) {
  .checkout-list-item-content {
    display: grid;
    grid-template-areas:
      "a a a"
      "b b c"
      "d d d"
      "e e e";
    grid-template-columns: 1fr 1fr 30px;
    grid-gap: 0.15rem;
    padding: 0.5rem 0;
  }

  .change-date .checkout-list-item-content,
  .confirm-removal .checkout-list-item-content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .checkout-list-item-actions {
    opacity: 0.7;
    padding-top: 0.1rem;
    grid-area: d;
  }

  .checkout-list-item-title {
    grid-area: b;
  }

  .checkout-list-item-dates {
    grid-area: a;
  }

  .checkout-list-item-remove {
    grid-area: c;
  }

  .checkout-list-item-money {
    grid-area: e;
  }

  .amount-discount {
    padding-top: 0.25rem;
  }
}
