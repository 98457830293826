@use "sass:meta";
@layer foundation, theme;

@include meta.load-css("animation/index");
@include meta.load-css("base/index");
@include meta.load-css("checkout/index");
@include meta.load-css("form/index");
@include meta.load-css("profile/index");
@include meta.load-css("progressbar/");
@include meta.load-css("cohort-list/cohort-filters");
@include meta.load-css("navigation/index");

:root {
  --modal-z-index: 99999;
  --cart-portal-z-index: 99998;
  --navigation-z-index: 99997;
  --hubspot-chat-z-index: 99996;
}

body #hubspot-messages-iframe-container {
  z-index: var(--hubspot-chat-z-index) !important;
}

#cohort-switch-portal,
#cart-portal {
  position: relative;
  z-index: var(--cart-portal-z-index);
}

.modal-root {
  z-index: var(--modal-z-index);
}

.skip-to-content {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  clip: rect(1px, 1px, 1px, 1px);
  color: #000 !important;
  background-color: #fff !important;
  z-index: 9999;
}

.skip-to-content:focus {
  width: auto;
  height: auto;
  clip: auto;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

html:has([data-disable-document-scroll="true"]) {
  overflow: hidden;
}

@media print {
  .print-button,
  #footer,
  #navigation {
    display: none !important;
  }
}
