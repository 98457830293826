.kup-error-message {
  margin-top: 5px;
  color: #d00;
  font-weight: bold;
}

.kup-program .kup-error-message {
  padding-left: 20px;
}

.kup-checkbox-label + .kup-error-message {
  margin-top: 10px;
}

.kup-program-selection > .kup-error-message {
  margin-bottom: 8px;
}
